/*
 * Composant génrique d'action d'une grille (filters, current page, sorts, etc).
 * 
 * Pour fonctionner, mettre un id de grid unique | Ajouter l'event onDataBound sur la Grid Kendo 
 */
import toastr from 'toastr';
export default class GridComponent {
    constructor($view) {

        this.$view = $view;
        this.IsTreeList = false;
        this.IsListView = false;
        this.$Grid = null;
        this.GridId = null;
        this.CustomCreateUrl = this.$view.data('custom-create-url');

        this.Init();
    }

    Init() {
        let self = this;
        console.log('init grid component');

        // on attend que la grille soit chargée
        $(function () {

            // Pour sauvegarder le type de grille et faire différer certain comporttement quand on est sur une treelist
            if (self.$view.data('istreelist') === true) {
                self.IsTreeList = true;

                // on set le selector de la treelist
                self.$Grid = $("div[data-role='treelist']", self.$view);
                self.GridId = self.$Grid.id;

                $("#expand-treelist").on("click", function () {
                    self.ExpandTreelist(self);
                });

            } else if (self.$view.data('islistview') === true) {
                self.IsListView = true;
                self.$Grid = $("div[data-role='listview']", self.$view);
                self.GridId = self.$Grid.id;
            } else {
                // on set le selector de la grid
                self.$Grid = $("div[data-role='grid']", self.$view);
                self.GridId = self.$Grid.id;
            }
        });


        window.sendAntiForgery = this.window_sendAntiForgery;

        window.onDataBound = this.window_onDataBound;
        window.onGridRowReorder = this.window_onGridRowReorder;
        window.getKendoGridSelector = this.window_getKendoGridSelector;
        window.FirstDataBound = false;

        this.$view.on("click", '.grid-bt-add', (e) => {

            console.log('click');

            if (self.CustomCreateUrl != null && self.CustomCreateUrl != "") {
                // Redirection vers la custom url
                window.location.href = self.CustomCreateUrl;
            }
            else {
                // Récupérer l'URL courante
                const currentUrl = window.location.pathname;

                // Ajouter "Create" à l'URL courante
                const createUrl = `${currentUrl}/Create`;

                // Rediriger vers l'action "Create"
                window.location.href = createUrl;
            }
        });


        //Popup de confirmation de suppression pour les grid inline
        this.$view.on("click", '.ConfirmInlineDeletePopup', (e) => {

            // Afin d'empécher le click sur le bouton de nous faire remonter en haut de page
            e.preventDefault(); //ne fonctionne pas :/

            //On récupère de façon générique la Grid Kendo de la page
            var $grid = null;

            if (self.IsTreeList) {
                //On récupère de façon générique la Grid Kendo de la page
                $grid = $("div[data-role='treelist']");
            } else {
                //On récupère de façon générique la Grid Kendo de la page
                $grid = $("div[data-role='grid']");
            }

            var kendoGrid = $grid.data("kendoGrid");
            var modelToDelete = kendoGrid.dataItem($(e.target).parents('tr'));

            // On récupère les data apposées sur le boutons
            let data = e.currentTarget.dataset;

            // on récupère le msg de confirmation de suppression
            var msg = data.confirmDeleteMsg;

            // On ouvre une popup de confirmation
            kendo.confirm(msg).then(function () {
                // COnfirm
                kendoGrid.dataSource.remove(modelToDelete);
                kendoGrid.dataSource.sync();
            }, function () {
                // Denied
            });
        });

        //Popup de confirmation d'action
        this.$view.on("click", '.ConfirmPopup', (e) => {

            // Afin d'empécher le click sur le bouton de nous faire remonter en haut de page
            e.preventDefault(); //ne fonctionne pas :/

            //On récupère de façon générique la Grid Kendo de la page
            var grid = null;

            if (self.IsTreeList) {
                //On récupère de façon générique la Grid Kendo de la page
                grid = $("div[data-role='treelist']")[0];
            } else if (self.IsListView) {
                grid = $("div[data-role='listview']")[0];
            } else {
                //On récupère de façon générique la Grid Kendo de la page
                grid = $("div[data-role='grid']")[0];
            }

            var gridName = grid.id;

            // On récupère les data apposées sur le boutons
            let data = e.currentTarget.dataset;

            // on récupère le msg de confirmation de suppression
            var msg = data.confirmDeleteMsg;

            // On ouvre une popup de confirmation
            kendo.confirm(msg).then(function () {

                // En cas de oui on call l'url
                $.ajax({
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8', // when we use .serialize() this generates the data in query string format. this needs the default contentType (default content type is: contentType: 'application/x-www-form-urlencoded; charset=UTF-8') so it is optional, you can remove it
                    type: 'POST',
                    data: { id: data.id },
                    url: data.url,
                    success: function (data) {
                        if (self.IsTreeList) {
                            $("#" + gridName).data("kendoTreeList").dataSource.read();
                        } else if (self.IsListView) {
                            window.location.reload();
                        } else {
                            $("#" + gridName).data("kendoGrid").dataSource.read();
                        }
                        if (data.resultOk === 0) {
                            toastr.error(data.resultMessage || "Une erreur est survenue");

                        } else {
                            toastr.success(data.resultMessage || "Suppression effectuée !");
                        }
                    },
                    error: function (data) {
                        // Le but est de n'afficher que les messages d'erreur provenant d'un return BadRequest()
                        if (data.responseText && data.status == 400) {
                            toastr.error(data.responseText);
                        } else {
                            toastr.error("Une erreur est survenue");
                        }
                    }
                });
            }, function () {
                // Denied
                console.log("Une erreur est survenue");
            });

        });
    }


    //Permet de refresh des données la grid
    RefreshGrid() {
        let self = this;

        var grid = self.$Grid.data("kendoGrid");
        grid.dataSource.read();
        grid.refresh();
    }

    // Pour les tree List uniquement
    ExpandTreelist(self) {

        //On récupère de façon générique la Grid Kendo de la page
        var treeList = $("div[data-role='treelist']", self.$view).data("kendoTreeList");

        var Options = treeList.getOptions();

        // En fonction de l'état actuel on va collapse ou expand
        Options.dataSource.schema.model.expanded = !Options.dataSource.schema.model.expanded
        treeList.setOptions(Options);

        // on change la visibilité de l'icone
        if (!Options.dataSource.schema.model.expanded) {
            $("#expand-treelist .fa-toggle-off").show();
            $("#expand-treelist .fa-toggle-on").hide();
        } else {
            $("#expand-treelist .fa-toggle-off").hide();
            $("#expand-treelist .fa-toggle-on").show();
        }
    }


    // On affiche un toast en cas d'erreur de la grille
    window_ErrorHandler(e) {

        if (e.xhr.responseText) {
            console.log(e.xhr.responseText);
        }

        toastr.options = {
            timeOut: 0,
            extendedTimeOut: 0
        };

        toastr.error("Une erreur est survenue");
    }


    // afin de récupérer le selecteur de la grille
    window_getKendoGridSelector() {
        //On récupère de façon générique la Grid Kendo de la page
        var $grid = null;

        //On récupère de façon générique la Grid Kendo de la page
        $grid = $("div[data-role='grid']");

        return $grid;
    }

    // Lors du reorder de la grille
    window_onGridRowReorder(e) {

        console.log('qsd');

        var $grid = getKendoGridSelector();

        // on récupère l'url du reorder dans le component
        var url = $grid.closest('[data-mount="GridComponent"]').data("reorder-url");

        if (!url) {
            alert('veuillez saisir un data-reorder-url="controller/action/id" sur le GridComponent !');
        }

        var kendoGrid = $grid.data("kendoGrid");

        // Utilise l'indice de la ligne réorganisée pour accéder à l'objet de données dans le tableau
        var id = kendoGrid.dataSource.at(e.oldIndex).Id;

        // ici on va calculer si on est sur une montée ou une descente
        var montee = false;
        if (e.newIndex > e.oldIndex) {
            montee = true;
        }

        // on set les index
        var data = {
            // Si on est en descente on incrémente de 1
            newOrder: e.newIndex + (montee ? 0 : 1),
            id
        };

        $.ajax({
            url: url,
            type: 'POST',
            data: data,
            success: function (data) {

                kendoGrid.dataSource.read();
                kendoGrid.refresh();

                toastr.success("mise à jour de l'ordre");
            }
        });
    }  

    window_sendAntiForgery() {
        return { "__RequestVerificationToken": $('input[name=__RequestVerificationToken]').val() }
    }

    window_onDataBound(e) {

        // Afin de définir si on est sur la premier databound
        if (window.FirstDataBound === false) {

            // On va faire le focus sur le champs de recherche
            $(".k-grid-toolbar .k-grid-search .k-input-inner").focus();

            // Afin que ça n'arrive que sur le premier
            window.FirstDataBound = true;

            // On check si la méthode existe sur la page
            var fn = window["FirstDataBoundMethod"];

            // Et que c'est bien une fonction
            if (typeof fn === 'function') {
                // On éxécute la méthode
                window.FirstDataBoundMethod();
            }
        }
    }
}