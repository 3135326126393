//import '@progress/kendo-ui'


class PopupIncludeChild {
    constructor() {
        this.Init();
    }

    Init() {
        console.log('PopupIncludeChild.js');
    }

    // Permet de gérer les popups de confirmation avec checkbox pour inclure les enfants
    SetupPopup(
        gridSelector,
        selector,
        title,
        confirmButtonText,
        paragraphContent,
        labelContent,
        isTreeList = false) {
        $(document).on("click", selector, (e) => {

            // Empêcher la navigation par défaut
            e.preventDefault();

            // Récupérer les informations nécessaires
            const button = $(e.currentTarget);
            const url = button.data('url');
            const $grid = $(gridSelector);


            // On gère le cas où c'est une treelist et le cas ou c'est une grid
            var kendoGrid = null;
            if (isTreeList) {
                kendoGrid = $grid.data("kendoTreeList");

            } else {
                kendoGrid = $grid.data("kendoGrid");

            }

            // Générer le contenu HTML dynamique
            const htmlString = `
            <p>${paragraphContent}</p>
            <div style='font-style: italic;font-size:0.8em'>
                <label class="form-check-label" for="include-child">${labelContent}</label>
                <input class="form-check-input" id="include-child" name="include-child" type="checkbox">
            </div>
        `;

            // Afficher la popup de confirmation
            Swal.fire({
                title: title,
                showCancelButton: true,
                html: htmlString,
                confirmButtonText: confirmButtonText,
                cancelButtonText: 'Annuler',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    const includeChildCb = document.getElementById('include-child');
                    const includeChild = includeChildCb && includeChildCb.checked;

                    const urlWithParams = `${url}&includeChild=${includeChild}`;

                    return new Promise((resolve, reject) => {
                        $.ajax({
                            url: urlWithParams,
                            type: 'POST',
                            success: (data) => resolve(data),
                            error: (error) => reject(error),
                        });
                    }).catch((error) => {
                        console.error(error);
                        Swal.showValidationMessage(`Une erreur est survenue...`);
                    });
                },
                allowOutsideClick: () => !Swal.isLoading(),
            }).then((result) => {
                if (result.isConfirmed) {
                    // Afficher le résultat via toastr
                    if (result.value.withErrors == 1) {
                        toastr.warning(result.value.resultMessage);
                    } else {
                        toastr.success(result.value.resultMessage);
                    }

                    // Rafraîchir la grille après un délai
                    setTimeout(() => {
                        kendoGrid.dataSource.read().done(() => {
                            kendoGrid.refresh();
                        });
                    }, 500);
                }
            });
        });
    }
}

module.exports = PopupIncludeChild;